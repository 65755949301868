import store from '@/store'
import { ref } from '@vue/composition-api'

export default function storageHelper() {
  // Is Storage MovedOut?
  //

  function isStorageBooked(theStorage) {
    // console.log('isStorageBooked')

    let storage = theStorage

    // eslint-disable-next-line no-prototype-builtins
    if (
      // eslint-disable-next-line operator-linebreak, no-prototype-builtins
      !storage.hasOwnProperty('order_items') ||
      // eslint-disable-next-line no-prototype-builtins
      !storage.hasOwnProperty('offer')
    ) {
      storage = store.getters.storageById(storage.id)
    }

    const highestOrderItemID = Math.max(...storage.order_items.map(e => e.id))

    const orderItemswithHighestSorted = storage.order_items.find(
      e => e.id === highestOrderItemID && e.moveOutDate === null
    )

    // console.log(orderItemswithHighestSorted)

    if (orderItemswithHighestSorted || storage.storageReservation === true) {
      return ref(true)
    }
    return ref(false)
  }

  function isStorageBookedBack(storage) {
    // eslint-disable-next-line no-unused-vars
    const highestOrderItemID = null
    let orderItemswithHighestSorted = []

    // eslint-disable-next-line no-prototype-builtins
    if (storage.hasOwnProperty('order_items')) {
      orderItemswithHighestSorted = storage.order_items.sort(
        (a, b) => b.id - a.id
      )
    } else {
      orderItemswithHighestSorted = store.getters
        .storageById(storage.id)
        .order_items.sort((a, b) => b.id - a.id)

      //       highestOrderItemID = Math.max(
      //           ...store.getters
      //             .storageById(storage.id)
      //             .order_items.map(e => e.id)
      // )
    }

    // console.log('orderItemswithHighestSorted')
    // console.log(orderItemswithHighestSorted)

    let isRented = false

    if (orderItemswithHighestSorted.length === 0) {
      return ref(isRented)
    }

    if (orderItemswithHighestSorted[0].moveOutDate === null) {
      isRented = true
    }

    return ref(isRented)
  }

  function isStorageRented(storage) {
    return isStorageBooked(storage)
  }

  // function countOfRentedStorages() {
  //   let rentedStorages = 0
  //   store.getters.storageEntries.forEach(storage => {
  //     if (isStorageBooked(storage).value) {
  //       rentedStorages += 1
  //     }
  //   })

  //   return ref(rentedStorages)
  // }

  function countOfRentedStorages() {
    let rentedStorages = 0

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < store.getters.storageEntries.length; ++i) {
      if (isStorageBooked(store.getters.storageEntries[i]).value) {
        rentedStorages += 1
      }
    }
    return ref(rentedStorages)
  }

  // function countOfNotRentedStorages() {
  //   let notRentedStorages = 0
  //   store.getters.storageEntries.forEach(storage => {
  //     if (!isStorageBooked(storage).value) {
  //       notRentedStorages += 1
  //     }
  //   })

  //   return ref(notRentedStorages)
  // }

  function countOfNotRentedStorages() {
    let notRentedStorages = 0

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < store.getters.storageEntries.length; ++i) {
      if (!isStorageBooked(store.getters.storageEntries[i]).value) {
        notRentedStorages += 1
      }
    }
    return ref(notRentedStorages)
  }

  function countOfRentedStoragesinLocation(location) {
    // console.log(location.storages)

    let rentedStoragesinLocation = 0
    store.getters.getAllStorageFromLocationId(location.id).forEach(storage => {
      if (isStorageBooked(storage).value) {
        rentedStoragesinLocation += 1
      }
    })

    return ref(rentedStoragesinLocation)
  }

  function countOfNotRentedStoragesinLocation(location) {
    // console.log(location.storages)
    let notRentedStoragesinLocation = 0
    store.getters.getAllStorageFromLocationId(location.id).forEach(storage => {
      if (!isStorageBooked(storage).value) {
        notRentedStoragesinLocation += 1
      }
    })

    return ref(notRentedStoragesinLocation)
  }

  // get StorageSumPrice
  function getStorageSumPrice(storage) {
    return ref(storage.price - storage.discount).value
  }

  return {
    isStorageBooked,
    isStorageBookedBack,
    isStorageRented,
    countOfRentedStorages,
    countOfNotRentedStorages,
    countOfRentedStoragesinLocation,
    countOfNotRentedStoragesinLocation,
    getStorageSumPrice,
  }
}
