import { ref } from '@vue/composition-api'
import moment from 'moment'

export default function helper() {
  function getFormattedDate(date) {
    return ref(moment(date).format('DD.MM.YYYY - HH:mm:ss')).value
  }

  function getFormattedDateNoTime(date) {
    return ref(moment(date).format('DD.MM.YYYY')).value
  }

  function getFormattedDiscount(discount) {
    let discountValue = ref('')
    if (discount === null) {
      // @ts-ignore
      discountValue = '-'
    } else {
      discountValue = discount
    }

    return discountValue
  }

  function getCountryOptions() {
    return ref(['Deutschland', 'Österreich', 'Schweiz', 'Andere']).value
  }

  function getSalutionOptions() {
    return ref(['Herr', 'Frau', 'Divers']).value
  }

  return {
    getFormattedDate,
    getFormattedDiscount,
    getFormattedDateNoTime,
    getCountryOptions,
    getSalutionOptions,
  }
}

// import { ref } from '@vue/composition-api'

//  export default function attachementHelper() {
//     function attachementTitleForID(attachementID) {
//       switch (attachementID) {
//         case 'personal_id':
//           return ref('Personalausweis').value

//         case 'contract':
//           return ref('Mietvertrag').value

//         case 'sepa':
//           return ref('SEPA').value

//         case 'trade_license':
//           return ref('Gewerbeschein').value

//         default:
//           return ref('Dokument').value
//       }
//     }

//     return {
//       attachementTitleForID,
//     }
//  }
