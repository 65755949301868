<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
      >
        <orders-info-cards :orders="orderbyID[0]" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
      >
        <orders-detail :orders="orderbyID" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import CustomerOrders from '@/views/pages/customers/view/CustomerOrders.vue'
import OrdersInfoCards from './OrdersViewInfoCards.vue'
import OrdersDetail from './OrdersDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    CustomerOrders,
    OrdersInfoCards,
    OrdersDetail,
  },
  props: ['id'],
  data() {
    return {}
  },
  computed: {
    orderbyID() {
      return this.$store.getters.orderbyID(Number(this.id))
    },
  },
}
</script>
