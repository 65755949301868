<template>
  <div>
    <!-- {{ orders }} -->
    <b-row class="match-height">
      <b-col>
        <b-card
          header="Kunde"
          :title="orders.customer.firstName + ' ' + orders.customer.lastName"
          :sub-title="orders.customer.email"
        >
          <b-card-text />
          <b-button
            variant="outline-primary"
            @click="onCustomerButtonClicked(orders.customer)"
          >
            Kundendetails
          </b-button>
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Bestellnotiz">
          <b-card-text>
            {{ orders.orderDetails }}
          </b-card-text>
        </b-card>
      </b-col>
      <!-- <b-col>
        <statistic-card-vertical
          color="primary"
          icon="UserIcon"
          :statistic="
            orders.customer.firstName + ' ' + orders.customer.lastName
          "
          statistic-title="<a>TEst</a>"
        />
        <a href="https://www.google.com">
          <span class="link-spanner"></span>
        </a>
      </b-col> -->
    </b-row>
    <b-row
      v-if="orders.orderDetails !== null"
      class="match-height"
    >
      <b-col>
        <statistic-card-vertical
          color="primary"
          icon="DollarSignIcon"
          :statistic="getTotalSumByOrderId(orders.id) + ' €'"
          statistic-title="Buchungssumme"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="primary"
          icon="HashIcon"
          :statistic="orders.id"
          statistic-title="Buchungsnummer"
        />
      </b-col>

      <b-col>
        <statistic-card-vertical
          color="primary"
          icon="CalendarIcon"
          :statistic="getFormattedDate(orders.orderDate)"
          statistic-title="Buchungsdatum"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BTable, BRow, BCol, BCardText, BButton } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import helper from '@/helper/helper'

export default {
  setup() {
    const { getFormattedDate } = helper()

    return { getFormattedDate }
  },
  components: {
    StatisticCardVertical,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardText,
    BButton,
  },
  props: {
    orders: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onCustomerButtonClicked(customer) {
      this.$router.push(`/customers-view/${customer.id}`)
    },
    getTotalSumByOrderId(id) {
      return this.$store.getters.getTotalSumByOrderId(Number(id))
    },
  },
}
</script>

<style>
/*Important:*/
.link-spanner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  /* edit: fixes overlap error in IE7/8, 
     make sure you have an empty gif 
  background-image: url('empty.gif');*/
}
</style>
