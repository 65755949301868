<template>
  <b-card title="Buchungen" style="overflow-x: scroll">
    <div>
      <!-- {{ orders }} -->
      <b-table-simple
        ref="table"
        hover
        outlined
        caption-top
        responsive="true"
        striped
        :items="orders"
        class="rounded-bottom mb-0"
        @row-clicked="onRowClicked"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th rowspan="2"> Nr </b-th>
            <b-th rowspan="2"> Datum </b-th>
            <b-th rowspan="2"> Details </b-th>
            <b-th rowspan="2"> Pos </b-th>
            <b-th rowspan="2"> Summe </b-th>
            <b-th colspan="6"> Buchugspositionen </b-th>
          </b-tr>
          <b-tr>
            <b-th>Mietstart</b-th>
            <b-th>Mietende</b-th>
            <b-th>Storage</b-th>
            <b-th>Standort</b-th>
            <b-th>Preis</b-th>
            <b-th>Ausgebucht</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-for="(order, index) in orders" :key="index">
          <b-tr>
            <b-td :rowspan="order.order_items.length + 1">
              {{ order.id }}
            </b-td>
            <b-td :rowspan="order.order_items.length + 1">{{
              getDate(order.orderDate)
            }}</b-td>
            <b-td :rowspan="order.order_items.length + 1">{{
              order.orderDetails
            }}</b-td>
            <b-td :rowspan="order.order_items.length + 1">{{
              order.order_items.length
            }}</b-td>
            <b-td variant="success" :rowspan="order.order_items.length + 1"
              ><strong>{{ getTotalSumByOrderId(order.id) }} €</strong></b-td
            >
          </b-tr>
          <b-tr v-for="(item, itemIndex) in order.order_items" :key="itemIndex">
            <b-td>{{ getFormattedDate(item.rentStart) }}</b-td>
            <b-td :variant="isRentActive(item.rentEnd)"
              >{{ getFormattedDate(item.rentEnd) }}
            </b-td>
            <b-td>{{ storagesById(item.storage).name }}</b-td>
            <b-td>{{ storagesById(item.storage).location.name }}</b-td>
            <b-td
              ><b-badge variant="success"> {{ item.price }} € </b-badge></b-td
            >
            <b-td
              ><b-button
                v-if="!item.moveOutDate"
                variant="danger"
                @click="showMoveOutModal(item)"
              >
                Ausbuchen?
              </b-button>
              <div v-else>
                {{ getFormattedDate(item.moveOutDate) }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-modal
        ref="modal-moveout"
        :v-model="moveOutItem"
        cancel-variant="outline-secondary"
        ok-title="Ausbuchen"
        cancel-title="Schließen"
        centered
        title="Ausbuchen"
        @ok="moveOutAction"
        @hide="hideMoveOutModal"
      >
        <b-form>
          <!-- {{ moveOutItem }} -->
          <p>
            Lager <strong>{{ storage.name }}</strong> in
            <strong>{{ storage.location.name }}</strong> wirklich ausbuchen?
          </p>

          <b-form-group>
            <label for="description">Beschreibung (optional)</label>
            <b-form-textarea
              id="textarea-default"
              v-model="moveOutItem.moveOutDescription"
              placeholder=""
              rows="2"
            />
          </b-form-group>
          <b-form-group>
            <label for="date">Datum</label>
            <flatpickr
              v-model="moveOutItem.moveOutDate"
              class="form-control"
              :config="{
                enableTime: true,
                altInput: true,
                altFormat: 'd-M-Y - H:i \\U\\h\\r',
                dateFormat: 'Z',
                locale: {
                  firstDayOfWeek: 1, // start week on Monday
                },
                time_24hr: true,
                // defaultDate: '24:00',
                defaultHour: '00',
                defaultMinute: '00',
              }"
            />
          </b-form-group>
        </b-form>
      </b-modal>
    </div>
  </b-card>
</template>
<script>
import moment from 'moment'
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import 'flatpickr/dist/flatpickr.min.css'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import Vueflatpickr from 'vue-flatpickr-component'
import helper from '@/helper/helper'

flatpickr.localize(German)

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCard,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    flatpickr: Vueflatpickr,
  },
  // props: ['orders'],
  setup() {
    const { getFormattedDate } = helper()

    return {
      getFormattedDate,
    }
  },
  data() {
    return {
      storage: {
        name: '',
        location: {},
      },
      moveOutItem: {},
    }
  },
  computed: {
    orders() {
      return this.$store.getters
        .orderByCustomerId(Number(this.$route.params.id))
        .sort((a, b) => b.id - a.id)
    },

    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    getDate(date) {
      return moment(date).format('DD.MM.YYYY - H:mm:ss')
    },
    storagesById(id) {
      return this.$store.getters.storageById(id)
    },
    getTotalSumByOrderId(id) {
      return this.$store.getters.getTotalSumByOrderId(Number(id))
    },
    isRentActive(rentEndDate) {
      return Date.now() < new Date(rentEndDate).getTime() ? 'success' : 'danger'
    },
    onRowClicked(item) {
      alert('Hello')
      this.$router.push(`/orders-view/${item.id}`)
    },
    showMoveOutModal(item) {
      // this.moveOutItem = item
      this.moveOutItem = JSON.parse(JSON.stringify(item))
      this.$refs['modal-moveout'].show()

      // console.log(item)
      this.storage = this.storagesById(this.moveOutItem.storage)
    },
    moveOutAction() {
      console.log('moveoutitem')
      console.log(this.moveOutItem)
      this.$store
        .dispatch('updateOrderItem', this.moveOutItem)
        .then(response => {
          if (response.moveOutDate !== null) {
            // this.$router.go()
            this.$store.dispatch('getOrderEntries')
            this.$store.dispatch('getStorageEntries')
            // setTimeout(() => {
            //   // this.$router.go()
            //   // this.$forceUpdate()
            // }, 1000)
            // this.$refs.table.refresh()
          }
        })
      // this.$store.getters.orderItemsEntries()
      // this.$router.go()
    },
    hideMoveOutModal() {
      // this.moveOutItem.moveOutDate = null
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
