<template>
  <b-card title="Buchungen" style="overflow-x: scroll">
    <div>
      <b-table-simple
        hover
        outlined
        caption-top
        responsive="true"
        striped
        class="rounded-bottom mb-0"
        @row-clicked="onRowClicked"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th rowspan="2"> Positionen </b-th>
            <b-th colspan="7"> Buchungspositionen </b-th>
          </b-tr>
          <b-tr>
            <b-th>Mietstart</b-th>
            <b-th>Mietende</b-th>
            <b-th>Storage</b-th>
            <b-th>Standort</b-th>
            <b-th>Preis</b-th>
            <b-th>Rabatt</b-th>
            <b-th>Summe</b-th>
          </b-tr>
        </b-thead>
        <b-tbody
          v-for="(order, index) in orders.sort(id).reverse()"
          :key="index"
        >
          <b-tr>
            <b-td :rowspan="order.order_items.length + 1">{{
              order.order_items.length
            }}</b-td>
          </b-tr>
          <b-tr v-for="(item, itemIndex) in order.order_items" :key="itemIndex">
            <b-td>{{ getFormattedDate(item.rentStart) }}</b-td>
            <b-td :variant="isRentActive(item.rentEnd)"
              >{{ item.rentEnd ? getFormattedDate(item.rentEnd) : 'Offen' }}
            </b-td>
            <b-td>{{ storagesById(item.storage).name }}</b-td>
            <b-td>{{ storagesById(item.storage).location.name }}</b-td>
            <b-td> {{ item.price }} € </b-td>
            <b-td> {{ item.discount ? item.discount : '0' }} € </b-td>
            <b-td
              ><b-badge variant="success">
                {{ getStorageSumPrice(item) }} €
              </b-badge></b-td
            >
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-card>
</template>
<script>
import helper from '@/helper/helper'
import storageHelper from '@/helper/storageHelper'

import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCard,
    BBadge,
  },

  setup() {
    const { getFormattedDate } = helper()
    const { getStorageSumPrice } = storageHelper()

    return {
      getFormattedDate,
      getStorageSumPrice,
    }
  },

  props: ['orders'],

  data() {
    return {
      storage: Object,
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    storagesById(id) {
      return this.$store.getters.storageById(id)
    },
    getTotalSumByOrderId(id) {
      return this.$store.getters.getTotalSumByOrderId(Number(id))
    },
    isRentActive(rentEndDate) {
      return Date.now() < new Date(rentEndDate).getTime() ? 'success' : 'danger'
    },
    onRowClicked(item) {
      alert('Hello')
      this.$router.push(`/orders-view/${item.id}`)
    },
  },
}
</script>
<style></style>
